import { Grid } from '@material-ui/core';
import GatsbyImage from 'gatsby-image';
import { styled } from 'linaria/react';
import React from 'react';
import { FiCheck } from 'react-icons/fi';
import { Borders } from '../Borders/Borders';
import { ContainerCustom } from '../ConatinerCustom/ContainerCustom';
import { HeadingWithNote } from '../HedingWithNote/HeadingWithNote';
import { Section } from '../Section/Section';
import { SponsorImagesVertical } from '../SponsorImagesVertical/SponsorImagesVertical';
import { theme } from '../Theme/Theme';

export interface TrainingEquipmentProps {
  className?: string;
}

const equipment = [
  'Tréningové pohodlné oblečenie, obuv na tatami (nie tenisky)',
  'Suspenzor (je to základ pre mužov, odporúčame aj pre ženy)',
  'Grapplingové rukavice (nácvik techník, drily, sparring)',
  'Boxerské rukavice a bandáže (sparring)',
  'Chránič na zuby (nácvik techník, sparring)',
  'Holenné chrániče (nácvik techník, drily, sparring)',
  'Chrániče predlaktí, hrudníku, helma (každý podľa seba)',
  'Malý uterák, voda, náhradné tričká (odporúčané doplnky)'
];

const TrainingEquipmentInner = (props: TrainingEquipmentProps) => {
  return (
    <Section className={props.className}>
      <ContainerCustom>
        <Borders topRight bottomRight>
          <div className="equipment">
            <HeadingWithNote
              heading={'VYBAVENIE PRE TRÉNING'}
              note="Kvalitné tréningové vybavenie chráni teba aj tvojho tréningového partnera. Radi ti poradíme pri výbere potrebného vybavenia."
            />
            <Grid container spacing={4}>
              <Grid item xs={12} md={9}>
                <ul>
                  {equipment.map(item => (
                    <li key={item}>
                      <span className="icon">
                        <FiCheck />
                      </span>
                      <span className="text">{item}</span>
                    </li>
                  ))}
                </ul>
              </Grid>
              <Grid item xs={12} md={3}>
                <SponsorImagesVertical />
              </Grid>
            </Grid>
          </div>
        </Borders>
      </ContainerCustom>
    </Section>
  );
};

export const TrainingEquipment = styled(TrainingEquipmentInner)`
  .equipment {
    padding: 40px 0px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    li {
      margin: 10px 0;
      display: flex;
    }
    .icon {
      color: ${theme.color.primary};
      margin-right: 20px;
      display: inline-flex;
      align-items: center;
      font-weight: 700;
    }
    .text {
      color: ${theme.color.primaryDark};
    }
  }
`;
