import { FormControlLabel, Grid } from '@material-ui/core';
import { styled } from 'linaria/react';
import { rgba } from 'polished';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { emailRegex } from '../../../lib/consts';
import { KVButton } from '../../Shared/KVButton/KVButton';
import { muiTheme, theme } from '../../Shared/Theme/Theme';
import {
  CheckboxLabel,
  FormItem,
  FormItemError,
  GreenCheckbox
} from '../../Landing/ContactUsSection/components/ContactUsForm/styled';
import { Note } from '../../Shared/Note/Note';
import { encode } from '../../Landing/ContactUsSection/components/ContactUsForm/ContactUsForm';

export interface SignUpFormProps {
  className?: string;
}

const requiredMessage = 'Toto pole je povinné';

// TO DO: replace with a query
const trainingPlaces = [
  'Základná škola Kuchyňa',
  'Aircraft Sport House',
  'Monkeyfit'
];

const SignUpFormInner = (props: SignUpFormProps) => {
  const { register, handleSubmit, errors, reset } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [agreed, setAgreed] = useState(false);

  const onSubmit = async (data: any) => {
    if (!agreed) {
      return Swal.fire(
        'Nesúhlasíte ?',
        'Musíte potvrdiť, že súhlasíte so spracovaním vašich údajov',
        'warning'
      );
    }
    console.log(data);

    setSubmitting(true);
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'signup',
        ...data
      })
    })
      .then(() => {
        Swal.fire(
          'Email úspešne odoslaný',
          'Váša registrácia bola úspešne odoslaná',
          'success'
        );
        setSubmitting(false);
        setAgreed(false);
      })
      .catch(error => alert(error))
      .finally(() => {
        reset();
      });
  };

  return (
    <form
      data-netlify="true"
      name="signup"
      className={props.className}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormItem>
            <input
              placeholder={`* Meno a priezvisko`}
              name="name"
              ref={register({
                required: true
              })}
            />
            {errors.name && <FormItemError>{requiredMessage}</FormItemError>}
          </FormItem>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormItem>
            <input
              placeholder={`* Email`}
              name="email"
              ref={register({
                required: true,
                pattern: emailRegex
              })}
            />
            {errors.email && (
              <FormItemError>Zadajte prosím správny email</FormItemError>
            )}
          </FormItem>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormItem>
            <input placeholder={`Mobil`} name="mobile" ref={register({})} />
          </FormItem>
        </Grid>

        <Grid item xs={6} md={3}>
          <FormItem>
            <select name="gender" ref={register}>
              <option disabled selected hidden>
                * Vyber pohlavie
              </option>
              {['muž', 'žena'].map(gender => (
                <option value={gender}>{gender}</option>
              ))}
            </select>
            {errors.place && <FormItemError>{requiredMessage}</FormItemError>}
          </FormItem>
        </Grid>

        <Grid item xs={6} md={3}>
          <FormItem>
            <input
              placeholder={`* Vek`}
              name="age"
              ref={register({
                required: true
              })}
            />
            {errors.age && <FormItemError>{requiredMessage}</FormItemError>}
          </FormItem>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormItem>
            <select name="place" ref={register}>
              <option disabled selected hidden>
                * Zo zoznamu si vyber miesto tréningu.
              </option>
              {trainingPlaces.map(place => (
                <option value={place}>{place}</option>
              ))}
            </select>
            {errors.place && <FormItemError>{requiredMessage}</FormItemError>}
          </FormItem>
        </Grid>

        <Grid item xs={12} md={6}>
          <Note
            text="Tvoje pohlave a vek nám pomôžu lepšie ťa 
            informovať a individualizovať tak tvoj prvý tréning."
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Note
            text="Vyber si zo zoznamu s rozvahou, pretože mesačné 
            predplatné je viazané na konkrétne miesto tréningu."
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormItem>
            <input
              placeholder={`Máš skúsenosti s bojovými športmi?`}
              name="experience"
              ref={register({
                required: false
              })}
            />
            {errors.experience && (
              <FormItemError>{requiredMessage}</FormItemError>
            )}
          </FormItem>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormItem>
            <input
              placeholder={`Odkiaľ si sa dozvedel o Krav Maga Warrior?`}
              ref={register}
              name="fromWhere"
            />
          </FormItem>
        </Grid>

        <Grid item xs={12}>
          <FormItem>
            <textarea
              rows={5}
              placeholder={`Ak sa nás chceš niečo spýtať, alebo potrebuješ doplňujúce informácie, neváhaj to napísať.`}
              name="message"
              ref={register({
                required: false
              })}
            />
            {errors.message && <FormItemError>{requiredMessage}</FormItemError>}
          </FormItem>
        </Grid>

        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justify="space-between"
        >
          <Grid item xs={12} md={3}>
            <KVButton loading={submitting} onClick={handleSubmit(onSubmit)}>
              Zapísať sa
            </KVButton>
          </Grid>
          <Grid item xs={12} md={9}>
            <FormControlLabel
              className="agreement-checkbox"
              control={
                <GreenCheckbox
                  name="agreement"
                  checked={agreed}
                  onChange={e => setAgreed(e.target.checked)}
                />
              }
              label={
                <CheckboxLabel className="checkbox-label">
                  Súhlasím so spracovaním osobných údajov s cieľom zasielania
                  obchodných oznamov, informácií o tréningoch a elektronickej
                  komunikácií.
                </CheckboxLabel>
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export const SignUpForm = styled(SignUpFormInner)`
  margin-top: 1.2rem;
  display: flex;
  border-radius: 5px;
  flex-flow: column;
  width: 76%;
  margin: auto;
  margin-top: 20px !important;

  @media (min-width: ${theme.breakpoints.sm}px) {
    padding: 0 4vw;
  }
  .Checkbox {
    display: flex;
    flex-flow: row;
    input {
      flex: 0;
    }
  }

  .agreement-checkbox {
    margin-left: 20px;
    flex: 1;

    ${muiTheme.breakpoints.down('sm')} {
      margin-top: 20px;
      margin-left: 0px;
    }
  }
  .MuiSvgIcon-root {
    font-size: 2.5rem;
  }

  input,
  textarea {
    border-radius: 2px;
    display: flex;
    flex: 1;
    padding: 1.5rem;
    border: 2px solid ${theme.color.grayDark};
    line-height: 2.6rem;
    font-size: 1.6rem;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    ::placeholder {
      color: ${rgba(theme.color.primaryDark, 0.5)};
      opacity: 1; /* Firefox */
    }
  }
  select {
    border-radius: 2px;
    display: flex;
    flex: 1;
    padding: 1.73rem 1.5rem;
    border: 2px solid ${theme.color.grayDark};
    line-height: 2.6rem;
    font-size: 1.6rem;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    color: ${rgba(theme.color.primaryDark, 0.5)};
    background-color: inherit;
    .placeholder {
      color: ${rgba(theme.color.primaryDark, 0.5)};
    }
  }
`;
