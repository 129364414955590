import React from 'react';
import { styled } from 'linaria/react';
import { theme, muiTheme } from '../Theme/Theme';
import { Vertical, Left, Right } from "./styled";

export interface ArrowProps {
  className?: string;
}

const ArrowInner = (props: ArrowProps) => {
  return (
    <div className={props.className}>
      <Vertical />
      <Left />
      <Right />
    </div>
  );
};

export const Arrow = styled(ArrowInner)`
  ${muiTheme.breakpoints.down('sm')} {
    display: none;
  }
  width: 60px;
  height: 65px;
  margin: auto;
  justify-content: center;
  position: relative;
`;
