import GatsbyImage from 'gatsby-image';
import { styled } from 'linaria/react';
import React from 'react';
import { useTrainingEquipmentImage } from './hooks/useTrainingEquipmentImage';

export interface SponsorImagesVerticalProps {
  className?: string;
}

const SponsorImagesVerticalInner = (props: SponsorImagesVerticalProps) => {
  const kmg = useTrainingEquipmentImage();
  return (
    <div className={props.className}>
      <GatsbyImage className="equipment-image" fluid={kmg} />
    </div>
  );
};

export const SponsorImagesVertical = styled(SponsorImagesVerticalInner)``;
