import React from 'react';
import { styled } from 'linaria/react';
import { Arrow } from '../../Shared/Arrow/Arrow';
import Grid from '@material-ui/core/Grid';
import { Horizontal } from '../../Shared/Arrow/styled';
import { KVButton } from '../../Shared/KVButton/KVButton';
import { Link } from 'gatsby';
import { routes } from '../../../lib/routes';
import { ContainerCustom } from '../../Shared/ConatinerCustom/ContainerCustom';
import { HeadingThree } from '../../Shared/Typography/HeadingThree/HeadingThree';

export interface ButtonLinksProps {
  className?: string;
}

const ButtonLinksInner = (props: ButtonLinksProps) => {
  return (
    <ContainerCustom className={props.className}>
      <Grid container>
        <Grid item sm={6} className="item">
          <Horizontal />
          <Arrow />
          <HeadingThree className="dark center heading">
            SLEDUJ NÁS NA FACEBOOKU
          </HeadingThree>
          <a href="https://www.facebook.com/KravMagaWarrior1" target="_blank">
            <KVButton narrow blue>
              FACEBOOK PAGE
            </KVButton>
          </a>
        </Grid>
        <Grid item sm={6} className="item">
          <Horizontal className="full" />
          <Arrow />
          <HeadingThree className="dark center heading">
            CHCEŠ VEDIEŤ VIAC O KRAV MAGA?
          </HeadingThree>
          <Link to={routes.warrior.to}>
            <KVButton narrow>INFO A HISTÓRIA</KVButton>
          </Link>
        </Grid>
      </Grid>
    </ContainerCustom>
  );
};

export const ButtonLinks = styled(ButtonLinksInner)`
  .item {
    position: relative !important;
  }
  .heading {
    margin: 20px 0 15px 0;
  }
  padding-bottom: 20px !important;
`;
