import React from 'react';
import Layout from '../components/Shared/Layout/Layout';
import { SEO } from '../components/Shared/SEO/SEO';
import { SignUpFormSection } from '../components/SignUp/SignUpFormSection/SignUpFormSection';
import { ButtonLinks } from '../components/SignUp/ButtonLinks/ButtonLinks';
import { TrainingEquipment } from '../components/Shared/TrainingEquipment/TrainingEquipment';

const NaborPage = () => {
  return (
    <Layout>
      <SEO title={'Zápis'}  />
      <SignUpFormSection />
      <TrainingEquipment />
      <ButtonLinks />
    </Layout>
  );
};

export default NaborPage;
