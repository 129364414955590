import { useStaticQuery, graphql } from 'gatsby';
import { FluidObject } from 'gatsby-image';

export const useTrainingEquipmentImage = (): FluidObject => {
  const { file } = useStaticQuery(graphql`
    query useTrainingEquipmentImage {
      file(relativePath: { eq: "partner-logos/partner2.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return file?.childImageSharp?.fluid;
};
