import { styled } from 'linaria/react';
import { theme, muiTheme } from '../Theme/Theme';

export const Line = styled.div`
  position: absolute;
  width: 3px;
  background: ${theme.color.primary};
`;

export const Vertical = styled(Line)`
  height: 60px;
  top: 31px;
  left: 50%;
  transform: translate(0, -50%);
`;

export const Horizontal = styled.div`
  ${muiTheme.breakpoints.down('sm')} {
    display: none;
  }
  position: absolute;
  top: 0;
  right: 0;
  height: 3px;
  width: 50%;
  float: right;
  background: ${theme.color.primary};
  &.full{
    width: 100%;
  }
`;

export const Left = styled(Line)`
  height: 30px;
  bottom: -1px;
  left: 20.4px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
`;

export const Right = styled(Line)`
  height: 30px;
  bottom: -1px;
  left: 39.5px;
  transform: translate(-50%, -50%);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
`;