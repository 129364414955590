import React from 'react';
import { styled } from 'linaria/react';
import { Section } from '../../Shared/Section/Section';
import Container from '@material-ui/core/Container';
import { HeadingTwo } from '../../Shared/Typography/HeadingTwo/HeadingTwo';
import { SignUpForm } from './SignUpForm';

export interface SignUpFormSectionProps {
  className?: string;
}

const headingText = "Zápis do Krav Maga Warrior"

const SignUpFormSectionInner = (props: SignUpFormSectionProps) => {
  return (
    <Section>
      <Container>
        <HeadingTwo className="center">{headingText}</HeadingTwo>
        <SignUpForm />
      </Container>
    </Section>
  );
};

export const SignUpFormSection = styled(SignUpFormSectionInner)``;
